import { render, staticRenderFns } from "./WhyPage.vue?vue&type=template&id=5dcc7e5a&scoped=true"
import script from "./WhyPage.vue?vue&type=script&lang=js"
export * from "./WhyPage.vue?vue&type=script&lang=js"
import style0 from "./WhyPage.vue?vue&type=style&index=0&id=5dcc7e5a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dcc7e5a",
  null
  
)


    import installComponents from "!/user/src/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BContainer})
    

export default component.exports