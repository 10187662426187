import { render, staticRenderFns } from "./Municipalities.vue?vue&type=template&id=4197b380"
import script from "./Municipalities.vue?vue&type=script&lang=js"
export * from "./Municipalities.vue?vue&type=script&lang=js"
import style0 from "./Municipalities.vue?vue&type=style&index=0&id=4197b380&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/user/src/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BRow, BCol} from 'bootstrap-vue'
    installComponents(component, {BRow, BCol})
    

export default component.exports