import { render, staticRenderFns } from "./ModalButton.vue?vue&type=template&id=3dad3f44&scoped=true"
import script from "./ModalButton.vue?vue&type=script&lang=js"
export * from "./ModalButton.vue?vue&type=script&lang=js"
import style0 from "./ModalButton.vue?vue&type=style&index=0&id=3dad3f44&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dad3f44",
  null
  
)


    import installComponents from "!/user/src/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports