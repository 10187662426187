import { render, staticRenderFns } from "./ModalBox.vue?vue&type=template&id=2d8a49ca&scoped=true"
import script from "./ModalBox.vue?vue&type=script&lang=js"
export * from "./ModalBox.vue?vue&type=script&lang=js"
import style0 from "./ModalBox.vue?vue&type=style&index=0&id=2d8a49ca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8a49ca",
  null
  
)


    import installComponents from "!/user/src/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BRow, BContainer})
    

export default component.exports